import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import HeaderSimple from "@src/components/page-header-simple"
import ResponsiveSection from "@src/components/container-responsive-section"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import HtmlContent from "@src/components/core-value-html"
import Link from "@src/components/core-link"
import ImageWrapper from "@src/components/core-image-wrapper"
import Image from "@src/components/core-image"

const StyledServiceLinks = styled(HtmlContent)`
  display: flex;
  justify-content: center;
  margin: 10px -20px 0;
  & > * {
    margin: 0 20px 0;
  }
`
const StyledHostsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media only screen and (${props => props.theme.screen.small.min}) {
    flex-flow: row wrap;
    align-items: flex-start;
    & > * {
      flex: 1 0 200px;
      margin: 0 10px 20px;
    }
  }
`
const Players = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 0 20px;
  & > .service-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`
const AmazonMusicWrapper = styled(ImageWrapper)`
  height: 100px;
  aspect-ratio: 245 / 100;
`

const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: {
        title,
        uri: pageUri,
        content,
        images: { header: headerImage },
        seo: pageSeo,
      },
    },
  } = data
  const contentParse = content
    .split(
      /<p>\s*(<a .+?>\s*<span.*?>\s*<img .+? \/>\s*<\/span>\s*<\/a>)\s*<\/p>\s*/
    )
    .filter(item => !!item)
  const mainContent = contentParse[0]
  const serviceLinks =
    (contentParse.length > 1 && contentParse.slice(1, -1).join("")) || ""
  const hostsContent =
    (contentParse.length > 1 &&
      contentParse[contentParse.length - 1]
        .split(/(<h3)/)
        .reduce((result, item) => {
          if (!item) {
            return result
          }
          if (result.length && result[result.length - 1] === "<h3") {
            result[result.length - 1] += item
          } else {
            result.push(item)
          }
          return result
        }, [])) ||
    []
  GTM.dataLayerPushPageInfo({
    template: "static-podcast",
  })
  const splitServiceLinks = serviceLinks.split("<a")
  // Remove amazon at index 3
  splitServiceLinks.splice(3, 1)
  const joinedServiceLinks = splitServiceLinks.join("<a")
  const amazonURL = "http://www.amazon.com/10adventures"

  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, "index"],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <HeaderSimple spacer="margin" image={headerImage}>
        <h1>{title}</h1>
      </HeaderSimple>
      <ResponsiveSection>
        <HtmlContent html={mainContent} />
      </ResponsiveSection>
      {serviceLinks ? (
        <ResponsiveSection>
          <Players>
            <Link to={amazonURL}>
              <Image
                src="/icons/amazon-music.png"
                alt="amazon-music"
                wrapper={AmazonMusicWrapper}
              />
            </Link>
            <div className="service-links">
              <span>Or any of these players</span>
              <StyledServiceLinks html={joinedServiceLinks} />
            </div>
          </Players>
        </ResponsiveSection>
      ) : null}
      <ResponsiveSection>
        <iframe
          width="100%"
          height="315"
          scrolling="no"
          src="https://www.podbean.com/player-v2/?i=t4qm6-abbdd2-pbblog-playlist&amp;share=1&amp;download=1&amp;skin=1&amp;btn-skin=8&amp;size=315"
          data-name="pb-iframe-player"
        ></iframe>
      </ResponsiveSection>
      {hostsContent && hostsContent.length ? (
        <ResponsiveSection>
          <StyledHostsList>
            {hostsContent.map((item, index) => (
              <HtmlContent key={`podcast-host-${index}`} html={item} />
            ))}
          </StyledHostsList>
        </ResponsiveSection>
      ) : null}
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
